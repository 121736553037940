import React, { useState, useEffect } from "react";

import {
	IconButton,
	Typography,
	Card,
	CardHeader,
	CardActions,
	CardContent,
	Button,
} from "@mui/material";

import { InfoBox, Marker } from "@react-google-maps/api";
import CloseIcon from "@mui/icons-material/Close";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
	en: {
		description: "Description:",
		weblink: "Web Link:",
	},
	es: {
		description: "Descripción:",
		weblink: "Sitio Web",
	},
	pt: {
		description: "Descrição:",
		weblink: "Local na rede Internet:",
	},
});

const CustomMarker = (props) => {
	const { location, close, setClose, shownMarkers } = props;
	const [show, setShow] = useState(true);
	const [isOpen, setOpen] = useState(false);

	useEffect(() => {
		if (isOpen) setOpen(false);
	}, [close]);

	return (
		<Marker
			position={{ lat: location.lat, lng: location.lng }}
			onClick={() => {
				setClose(close + 1);
				setOpen(!isOpen);
			}}
			visible={show}
		>
			{isOpen && (
				<InfoBox
					onCloseClick={() => {
						setOpen(false);
					}}
					options={{
						closeBoxURL: ``,
						enableEventPropagation: true,
					}}
				>
					<Card>
						<CardHeader
							action={
								<IconButton
									onClick={() => {
										setOpen(false);
									}}
								>
									<CloseIcon />
								</IconButton>
							}
							title={location.name}
						/>
						<CardContent>
							<Typography sx={{ mb: 1.5 }} color="text.secondary">
								{location.address}
							</Typography>
							<Typography variant="body2">
								{strings.description} {location.info}
							</Typography>
							<Typography variant="body2">
								{strings.weblink} {location.website}
							</Typography>
						</CardContent>
						<CardActions>
							<Button size="small">Learn More</Button>
						</CardActions>
					</Card>
				</InfoBox>
			)}
		</Marker>
	);
};

// compose(
// 	withStateHandlers(
// 		() => ({
// 			isOpen: false,
// 		}),
// 		{
// 			onToggleOpen:
// 				({ isOpen }) =>
// 				() => ({
// 					isOpen: !isOpen,
// 				}),
// 		}
// 	)
// )((props) => {
// 	const { location, close, setClose, shownMarkers } = props;
// 	const [show, setShow] = useState(true);
// useEffect(() => {
// 	if (props.isOpen) props.onToggleOpen();
// }, [close]);

// 	useEffect(() => {
// 		if (shownMarkers === null || shownMarkers.has(location)) setShow(true);
// 		else setShow(false);
// 	}, [shownMarkers]);

// 	return (
// 		<Marker
// 			position={{ lat: location.lat, lng: location.lng }}
// 			onClick={() => {
// 				setClose(close + 1);
// 				props.onToggleOpen();
// 			}}
// 			// style={{ display: show ? "block" : "none" }}
// 			visible={show}
// 			// noRedraw={false}
// 		>
// 			{props.isOpen && (
// 				<InfoBox
// 					onCloseClick={props.onToggleOpen}
// 					options={{
// 						closeBoxURL: ``,
// 						enableEventPropagation: true,
// 					}}
// 				>
// 					<Card sx={{ minWidth: 275 }}>
// 						<CardHeader
// 							action={
// 								<IconButton onClick={props.onToggleOpen}>
// 									<CloseIcon />
// 								</IconButton>
// 							}
// 							title={location.name}
// 						/>
// 						<CardContent>
// 							<Typography sx={{ mb: 1.5 }} color="text.secondary">
// 								{location.address}
// 							</Typography>
// 							<Typography variant="body2">
// 								{location.info}
// 							</Typography>
// 						</CardContent>
// 						<CardActions>
// 							<Button size="small">Learn More</Button>
// 						</CardActions>
// 					</Card>
// 				</InfoBox>
// 			)}
// 		</Marker>
// 	);
// });

export default CustomMarker;
