import React, { useState, useEffect } from "react";
// import { compose, withProps, withState, withHandlers } from "recompose";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import CustomMarker from "./CustomMarker";

const GMap = (props) => {
	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		googleMapsApiKey: "AIzaSyDvsuqOypZ_yqBTjRJTiCOQKsKZYOXuswk",
	});

	const { locations, markerFilter, zoom } = props;

	const [map, setMap] = useState(null);
	const [close, setClose] = useState(0);
	const [shownMarkers, setShownMarkers] = useState(null);
	useEffect(() => {
		let stored = new Set();
		locations.map((loc) => {
			stored.add(`${loc.lat}||${loc.lng}`);
		});
		setShownMarkers(stored);
	}, [markerFilter]);

	// const onLoad = React.useCallback(function callback(map) {
	// 	const bounds = new window.google.maps.LatLngBounds();
	// 	// console.log(bounds);
	// 	map.fitBounds(bounds);
	// 	setMap(map);
	// }, []);

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null);
	}, []);

	return isLoaded ? (
		<GoogleMap
			mapContainerStyle={props.mapContainerElement}
			center={props.center}
			// defaultCenter={props.center}
			zoom={zoom}
			// onLoad={onLoad}
			onUnmount={onUnmount}
		>
			{locations.map((loc) => (
				<CustomMarker
					key={`${loc.lat}||${loc.lng}}||${Date.now}`}
					location={loc}
					close={close}
					setClose={setClose}
					shownMarkers={shownMarkers}
				/>
			))}
		</GoogleMap>
	) : (
		<></>
	);
};

// compose(
// 	withProps({
// 		googleMapURL:
// 			"https://maps.googleapis.com/maps/api/js?key=AIzaSyDvsuqOypZ_yqBTjRJTiCOQKsKZYOXuswk&v=3.exp&libraries=geometry,drawing,places",
// 		loadingElement: <div style={{ height: `100%` }} />,
// 		mapElement: <div style={{ height: `100%` }} />,
// 	}),
// 	withScriptjs,
// 	withGoogleMap,
// 	withState("zoom", "onZoomChange", 5),
// 	withHandlers(() => {
// 		const refs = {
// 			map: undefined,
// 		};

// 		return {
// 			onMapMounted: () => (ref) => {
// 				refs.map = ref;
// 			},
// 			onZoomChanged:
// 				({ onZoomChange }) =>
// 				() => {
// 					onZoomChange(refs.map.getZoom());
// 				},
// 		};
// 	})
// )((props) => {
// 	const { locations, markerFilter } = props;
// const [close, setClose] = useState(0);
// const [shownMarkers, setShownMarkers] = useState(null);
// useEffect(() => {
// 	let stored = new Set();
// 	locations.map((loc) => {
// 		stored.add(`${loc.lat}||${loc.lng}`);
// 	});
// 	setShownMarkers(stored);
// }, [markerFilter]);
// 	return (
// 		<GoogleMap
// 			zoom={props.zoom}
// 			center={props.center}
// 			ref={props.onMapMounted}
// 			onZoomChanged={props.onZoomChanged}
// 		>
// {locations.map((loc) => (
// 	<CustomMarker
// 		key={`${loc.lat}||${loc.lng}}||${Date.now}`}
// 		location={loc}
// 		close={close}
// 		setClose={setClose}
// 		shownMarkers={shownMarkers}
// 	/>
// ))}
// 		</GoogleMap>
// 	);
// });

export default GMap;
