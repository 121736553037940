import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
	Box,
	Paper,
	Grid,
	List,
	ListItem,
	Divider,
	ListItemText,
	ListItemButton,
	Typography,
	TextField,
	FormGroup,
	FormControl,
	Fab,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import GMap from "./components/GMap";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
	en: {
		search: "Search Here",
		showFilters: "Show Filters",
		hideFilters: "Hide Filters",
	},
	es: {
		search: "Busca Aquí",
		showFilters: "Mostrar Filtros",
		hideFilters: "Ocultar Filtros",
	},
	pt: {
		search: "Procure Aqui",
		showFilters: "Mostrar Filtros",
		hideFilters: "Ocultar Filtros",
	},
});

let locations = require("./locations.json");
let heightPx = 500;
let smHeightPx = 150;

// try {
// 	navigator.geolocation.getCurrentPosition(function (position) {
// 		console.log("Latitude is :", position.coords.latitude);
// 		console.log("Longitude is :", position.coords.longitude);
// 	});
// } catch (err) {
// }

let categories = {};
locations.map((loc) => {
	loc.categories.map((cat) => {
		if (categories[cat] === undefined) categories[cat] = true;
	});
});

const clearAccent = (str) => {
	str = str.replace(/[àáâãäå]/, "a");
	str = str.replace(/[eéèëê]/, "e");
	str = str.replace(/[iíìïî]/, "i");
	str = str.replace(/[oóòõöô]/, "o");
	str = str.replace(/[uúùüû]/, "u");
	str = str.replace(/[ç]/, "c");

	return str.replace(/[^a-z0-9]/gi, "");
};

// const StyledList = styled("List")(({ theme }) => ({
// 	// padding: theme.spacing(1),

// }));

function App() {
	const [center, setCenter] = useState({
		lat: locations[0].lat,
		lng: locations[0].lng,
	});
	const [zoom, setZoom] = useState(3);
	const [locs, setLocs] = useState(locations);
	const [search, setSearch] = useState("");
	const [showFilters, setShowFilters] = useState(false);
	const [filters, setFilters] = useState(categories);
	const [markerFilter, setMarkerFilter] = useState(0);

	const filterLocs = (search, filters) => {
		let checkedCats = Object.keys(categories).filter((k) => filters[k]);
		let checkFilt = (loc) => {
			var ret = false;
			for (const k of checkedCats) {
				if (loc.categories.includes(k)) {
					ret = true;
					break;
				}
			}
			if (
				search === "" ||
				clearAccent(loc.name.toLowerCase()).includes(search) ||
				clearAccent(loc.address.toLowerCase()).includes(search)
			)
				return ret;
			return false;
		};
		let newLocs = locations.filter(checkFilt);
		setLocs(newLocs);
		setMarkerFilter(markerFilter + 1);
	};

	const onChangeSearch = (event) => {
		let search = clearAccent(event.target.value.toLowerCase());
		setSearch(search);
		filterLocs(search, filters);
	};

	const onChangeFilter = (targ) => (event) => {
		let next = { ...filters };
		next[targ] = event.target.checked;
		setFilters(next);
		filterLocs(search, next);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={3} style={{ textAlign: "center" }}>
				<Box component="form" noValidate autoComplete="off" padding={1}>
					<TextField
						id="search"
						label={strings.search}
						variant="outlined"
						fullWidth
						value={search}
						onChange={onChangeSearch}
					/>
					<Fab
						variant="extended"
						color="primary"
						size="small"
						aria-label={strings.showFilters}
						onClick={() => {
							setShowFilters(!showFilters);
						}}
						style={{
							margin: 0,
							top: 5,
							right: "auto",
							bottom: 50,
							left: "auto",
						}}
					>
						{!showFilters ? (
							<ArrowDropDownIcon />
						) : (
							<ArrowDropUpIcon />
						)}

						{!showFilters
							? strings.showFilters
							: strings.hideFilters}
					</Fab>
					<FormControl
						component="fieldset"
						variant="standard"
						sx={{ display: showFilters ? "block" : "none" }}
					>
						<FormGroup>
							{Object.keys(categories).map((cat) => (
								<FormControlLabel
									control={
										<Checkbox
											checked={filters[cat]}
											onChange={onChangeFilter(cat)}
											name={cat}
										/>
									}
									label={cat}
									key={cat + "-key"}
								/>
							))}
						</FormGroup>
					</FormControl>
				</Box>
				<List
					sx={{
						width: "100%",
						bgcolor: "background.paper",
						overflow: "auto",
						maxHeight: {
							xs: smHeightPx,
							md: heightPx,
						},
					}}
				>
					{locs.map((loc) => (
						<React.Fragment key={`${loc.lat}||${loc.lng}`}>
							<ListItem alignItems="flex-start">
								<ListItemButton
									onClick={() => {
										setCenter({
											lat: loc.lat,
											lng: loc.lng,
										});
										let nextZoom = zoom != 8 ? 8 : 9;
										setZoom(nextZoom);
									}}
								>
									<ListItemText
										primary={loc.name}
										secondary={
											<React.Fragment>
												<Typography
													sx={{ display: "inline" }}
													// component="span"
													variant="body2"
													color="text.primary"
												>
													{loc.address}
												</Typography>
												<br />
												<Typography variant="body3">
													{loc.info}
												</Typography>
												<br />
												<Typography variant="body3">
													{loc.website}
												</Typography>
											</React.Fragment>
										}
									/>
								</ListItemButton>
							</ListItem>
							<Divider variant="inset" component="li" />
						</React.Fragment>
					))}
				</List>
			</Grid>
			<Grid item xs={12} md={9}>
				<GMap
					locations={locs}
					mapContainerElement={{
						height: `${heightPx + 100}px`,
						width: "100%",
					}}
					center={center}
					zoom={zoom}
					markerFilter={markerFilter}
				/>
			</Grid>
		</Grid>
	);
}

export default App;
